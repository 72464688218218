.widget {
    margin-bottom: 40px;
}

.widget:last-child {
    margin-bottom: 0;
}

.widget-title {
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;
    padding-bottom: 7px;
    border-bottom: 1px solid #eeeeee;
    font-size: 1.1rem;
    font-weight: 800;
}

.widget-title::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50px;
    height: 1px;
    bottom: -1px;
    background-color: #fe4a55;
}

.widget_tag_cloud .widget-title {
    margin-bottom: 17px;
}

.tagcloud a {
    display: inline-block;
    background: #f5f5f5;
    color: #221638;
    padding: 7px 15px;
    border: none;
    border-radius: 3px;
    font-weight: 700;
    font-size: 14px !important;
    margin-top: 8px;
    margin-right: 4px;
}

.widget_tag_cloud a:hover,
.widget_tag_cloud a:focus {
    color: #ffffff;
    background-color: #fe4a55;
}